<template>
  <ul class="ok-slideshow">
    <li v-for="image in slides" :key="image" :style="{ backgroundImage: `url(${image})` }"></li>
  </ul>
</template>

<script>
export default {
  name: 'Slideshow',
  data: () => ({
    slides: [
      'https://koleroviy.com/wp-content/uploads/2022/02/ok-lead-1.jpg',
      'https://koleroviy.com/wp-content/uploads/2022/02/ok-lead-2.jpg',
      'https://koleroviy.com/wp-content/uploads/2022/02/ok-lead-3.jpg',
      'https://koleroviy.com/wp-content/uploads/2022/02/ok-lead-4.jpg'
    ]
  })
}
</script>

<style lang="scss">
.ok {
  &-slideshow {
    position: fixed;
    background-color: #212121;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: -1;

    li {
      position: absolute;
      background-position: center;
      background-size: cover;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      backface-visibility: hidden;
      animation: slideAnimation 24s linear infinite 0s;
    }
    @for $i from 1 through 4 {
      $delay: 6;
      li:nth-child(#{$i}) {
        animation-delay: #{$delay * $i - $delay}s;
      }
    }
  }
}

@keyframes slideAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    transform: scale(1.05);
    animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    transform: scale(1.1);
  }

  30% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 0
  }
}
</style>
